var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#000","title":null,"subtitle":null,"shape":"square","finish-button-text":"Finalizar","next-button-text":"Próximo","back-button-text":"Voltar"},on:{"on-complete":_vm.formSubmitted,"on-change":_vm.alterandoTab},scopedSlots:_vm._u([{key:"footer",fn:function(props){return [_c('div',{staticClass:"wizard-footer-left"},[(props.activeTabIndex > 0 && !props.isLastStep)?_c('wizard-button',{style:(props.fillButtonStyle),nativeOn:{"click":function($event){return props.prevTab()}}},[_vm._v(" Voltar ")]):_vm._e()],1),_c('div',{staticClass:"wizard-footer-right"},[(!props.isLastStep)?_c('wizard-button',{staticClass:"wizard-footer-right",style:(props.fillButtonStyle),nativeOn:{"click":function($event){return props.nextTab()}}},[_vm._v(" Próximo ")]):_c('wizard-button',{staticClass:"wizard-footer-right finish-button",style:(props.fillButtonStyle),nativeOn:{"click":function($event){return props.nextTab()}}},[_vm._v(" "+_vm._s(props.isLastStep ? 'Finalizar' : 'Próximo')+" ")])],1)]}}])},[_c('tab-content',{attrs:{"title":"Informações do Hit","before-change":_vm.validationHitInformacoes}},[_c('validation-observer',{ref:"hitInformacoesRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Informações do Hit ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Insira as informações ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome do Hit","label-for":"hitNome"}},[_c('validation-provider',{attrs:{"name":"hitNome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"hitNome","state":errors.length > 0 ? false:null,"placeholder":"Digite o nome do seu hit"},model:{value:(_vm.hitNome),callback:function ($$v) {_vm.hitNome=$$v},expression:"hitNome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Compositores","label-for":"hitCompositores"}},[_c('validation-provider',{attrs:{"name":"hitCompositores","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"hitCompositores","state":errors.length > 0 ? false:null,"placeholder":"Digite o(s) nome(s) do(s) compositore(s)"},model:{value:(_vm.hitCompositores),callback:function ($$v) {_vm.hitCompositores=$$v},expression:"hitCompositores"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Pretensão - Valor de Liberação","label-for":"hitValorLiberacao"}},[_c('validation-provider',{attrs:{"name":"hitValorLiberacao","vid":"hitValorLiberacao"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control lg",attrs:{"id":"hitValorLiberacao"},model:{value:(_vm.hitValorLiberacao),callback:function ($$v) {_vm.hitValorLiberacao=$$v},expression:"hitValorLiberacao"}},'money',_vm.money,false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Pretensão - Valor de Exclusividade","label-for":"hitValorExclusividade"}},[_c('validation-provider',{attrs:{"name":"Valor de Exclusividade"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control lg",attrs:{"id":"hitValorExclusividade"},model:{value:(_vm.hitValorExclusividade),callback:function ($$v) {_vm.hitValorExclusividade=$$v},expression:"hitValorExclusividade"}},'money',_vm.money,false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-alert',{staticClass:"mt-1",attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("ATENÇÃO: Os valores informados serão o teto para a venda da sua composição. Você não poderá solicitar um valor maior do que o informado durante uma negociação.")])])])],1),_c('tab-content',{attrs:{"title":"Tema","before-change":_vm.validationHitTema}},[_c('validation-observer',{ref:"hitTemaRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Tema ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Selecione um ou mais temas.")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"hitTema"}},[_c('validation-provider',{attrs:{"name":"HitTema"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{staticClass:"row col-12",attrs:{"id":"hitTemas","options":_vm.temas,"name":"hitTemas","buttons":"","button-variant":"outline-dark col-6 col-md-3 mb-1"},model:{value:(_vm.hitTemas),callback:function ($$v) {_vm.hitTemas=$$v},expression:"hitTemas"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Estilo","before-change":_vm.validationHitEstilo}},[_c('validation-observer',{ref:"hitEstiloRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Estilo ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Selecione um ou mais estilos.")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"hitEstilo"}},[_c('validation-provider',{attrs:{"name":"HitEstilo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{staticClass:"row col-12",attrs:{"id":"hitEstilos","options":_vm.estilos,"name":"hitEstilos","buttons":"","button-variant":"outline-dark col-6 col-md-3 mb-1"},model:{value:(_vm.hitEstilos),callback:function ($$v) {_vm.hitEstilos=$$v},expression:"hitEstilos"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Letra","before-change":_vm.validationHitLetra}},[_c('validation-observer',{ref:"hitLetraRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Letra ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Preencha a letra da música.")]),_c('b-alert',{staticClass:"mt-1",attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("* Não é permitido inserir "),_c('strong',[_vm._v("nome de compositor")]),_vm._v(" na letra da música.")])])])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"hitLetra"}},[_c('validation-provider',{attrs:{"name":"hitLetra","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"hitLetra","placeholder":"Letra da música","rows":"8"},model:{value:(_vm.hitLetra),callback:function ($$v) {_vm.hitLetra=$$v},expression:"hitLetra"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Guia","before-change":_vm.validationHitGuia}},[_c('validation-observer',{ref:"hitGuiaRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"xl":"6","cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Guia (Arquivo) ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Carregue o arquivo da sua guia.")]),_c('br'),_c('br'),_c('span',{staticClass:"mt-1"},[_vm._v(" - Tamanho máximo do arquivo: "),_c('b',[_vm._v("20mb")]),_c('br'),_vm._v(" - Formatos aceitos: "),_c('b',[_vm._v(".mp3, .wav, .ogg")]),_c('br'),_vm._v(" - A voz da guia precisa estar com uma boa qualidade"),_c('br')]),_c('b-alert',{staticClass:"mt-1",attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Importante: A guia não pode conter "),_c('strong',[_vm._v("assinatura de compositor")])])])])],1),_c('b-col',{attrs:{"xl":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Arquivo","label-for":"hitGuia"}},[_c('validation-provider',{attrs:{"name":"hitGuia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"hitGuia","state":Boolean(_vm.hitGuia),"accept":"audio/mp3","placeholder":"Faça o upload do arquivo"},on:{"change":function($event){return _vm.teste($event)}},model:{value:(_vm.hitGuia),callback:function ($$v) {_vm.hitGuia=$$v},expression:"hitGuia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('b-modal',{ref:"hitCarregando",attrs:{"id":"hitCarregando","centered":"","title":"Enviando Hit","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":""}},[_c('b-card-text',{staticClass:"text-center p-2"},[_c('strong',[_vm._v("Seu hit está sendo carregado.")]),_c('br'),_vm._v(" Não feche e não atualize essa página."),_c('br'),_c('b-spinner',{staticClass:"mt-2",attrs:{"variant":"primary"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }